body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.extended-first-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
  padding: 2em;
}

.extended-first-div-h1{
  font-size: 1.3em;
  font-weight: bolder;
  text-align: center;
}

.extended-first-div-p{
  font-size: 0.9em;
  color: gray;
  margin-bottom: 4em;
  text-align: center;
}
.extended-first-div-divdash{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
  padding: 2em;

}

.illustration-whoops-h1{
  font-weight: bolder;
  font-size: 1.3em;
  text-align: center;
}
.illustration-whoops{
  width: 100%;
}

.illustration-whoops-p{
  text-align: center;
}

@tailwind base;
@tailwind components;
@tailwind utilities;